@import "../../index.scss";

.pages {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: hidden;

  .button {
    width: max-content;
  }
}

.page_item {
  width: auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 10px 8px 10px;
  gap: 6px;
  border-radius: 4px 4px 0px 0px;
  border-top: 1px solid var(--Main-pallet-Grayscale-60, #3D3D3D);
  border-right: 1px solid var(--Main-pallet-Grayscale-60, #3D3D3D);
  border-left: 1px solid var(--Main-pallet-Grayscale-60, #3D3D3D);
  background: var(--Main-pallet-Grayscale-100, #161618);
  z-index: 5;
  transform: translateY(3px);
  position: relative;
  transition: .3s;

  &:hover {
    cursor: pointer;
  }

  p {
    color: var(--Main-pallet-Grayscale-50, #767676);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    white-space: nowrap;
  }
}

.more_page {
  width: 18px;
  height: 18px;
  min-height: 18px;
  min-width: 18px;

  &:hover {
    cursor: pointer;
  }
}

.active {
  border-radius: 4px 4px 0px 0px;
  border-top: 1px solid var(--Main-pallet-Accent-70, #B64E17);
  border-right: 1px solid var(--Main-pallet-Accent-70, #B64E17);
  border-left: 1px solid var(--Main-pallet-Accent-70, #B64E17);
  background: #1E1E21;
  transform: translateY(1px);

  p {
    color: var(--Main-pallet-Accent-100, #EC7C40);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
  }
}

.add_page {
  border: 1px #EC722E solid;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #EC722E;
  width: 50px;
  font-size: 20px;
  user-select: none;
  cursor: pointer;
  transition: .3s;
  &:hover{
    color: #FFF;
    background-color: #EC722E;
  }
}
.page_tools{
  float: right;
  flex: 1 0 auto;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  .tools_icon{
    cursor: pointer;
    &:hover{
      svg{
        pointer-events: none;
        height: 20px;
        width: 25px;
        transition: .3s;
        &:hover{
          display: none;
          path{
            transition: .3s;
            fill: #EC722E !important;
          }
        }
      }
    }
    svg{
      pointer-events: none;
      height: 20px;
      width: 25px;
      transition: .3s;
      &:hover{
        path{
          transition: .3s;
          fill: #EC722E !important;
        }
      }
    }
  }
}

.contents {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: calc(100vw - 300px);

  .top {
    border-bottom: 1px solid #B64E17;
    position: relative;

    .swiper {
      margin: 0;
      width: 100%;
    }

    .slide_wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      box-shadow: 0px 4px 15px 0px #20202014;
      border-radius: 30px;
      padding: 20px;
      user-select: none;
    }

    .next_arrow {
      position: absolute;
      top: 18%;
      right: 0;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      z-index: 2;
      cursor: pointer;
      transition: .2s;

      &::after {
        content: "";
        width: 23px;
        height: 23px;
        background-image: url("../../assets/icons/next_default.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%;
      }
    }

    .prev_arrow{
      transform: rotate(180deg);
      right: auto;
      left: 0;
      top: 23%;
      width: 23px;
      height: 23px;
    }
  }

  .left {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    ul {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    li {
      list-style: none;
    }

    .section {
      /*transition: all 0.7s ease-in-out;*/
      position: relative;

      &:hover {
        cursor: pointer;
      }

      .section_wrap {
        overflow: hidden;
        height: 54px;
        display: flex;
        padding: 15px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        border-radius: 8px;
        background: #1E1E21;

          &.hide {
            height: 100%;

          .arrow_tools {
            transform: rotate(180deg);
          }
        }
      }

      .arrow_tools {
        /*transition: all 0.5s ease-in-out;*/
        transform: rotate(0);

        &:hover {
          cursor: pointer;
        }
      }

      .more_content {
        &:hover {
          cursor: pointer;
        }
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        width: 100%;


        p {
          color: var(--Main-pallet-Grayscale-0, #FFF);
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; /* 23.4px */
          letter-spacing: 0.18px;
          flex-grow: 1;

          span {
            color: var(--Main-pallet-Accent-100, #EC7C40);
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%; /* 23.4px */
            letter-spacing: 0.18px;
          }
        }

        .info_block {
          display: flex;
          gap: 20px;

          .header_item{
            font-size: 16px;
            .title {
              color: #EC722E;
            }

            .value{
              color: green;
            }
          }
        }
      }

      .block {
        display: flex;
        padding: 15px;
        gap: 15px;
        align-self: stretch;
        border-radius: 5px;
        background: var(--Main-pallet-Grayscale-80, #232428);

        header {
          display: flex;
          justify-content: space-between;

          .info_block {
            display: flex;
            gap: 5px;
            flex-direction: column;
            border-right: 2px solid #2D2D2D;
            padding-right: 15px;

            .header_item {
              font-size: 16px;
              column-gap: 5px;
              width: 210px;
              position: relative;
              display: grid;
              grid-template-columns: 1fr 2fr;

              .title {
                color: var(--Main-pallet-Grayscale-30, #CCC);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 24px */
                letter-spacing: 0.16px;
                width: 70px;
              }

              .value {
                color: var(--Main-pallet-Accent-80, #C35C25);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 24px */
                letter-spacing: 0.16px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 12ch;
                transition: all 0.5s ease-in-out;

                //&:hover {
                //  overflow: visible;
                //  cursor: pointer;
                //  z-index: 1;
                //}
              }
            }
          }
        }

        .content {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 56px;
          border-right: 2px solid #2D2D2D;
          padding-right: 15px;
          height: 100%;

          .lang_block {
            @include lang_block;
          }

          .text_block {
            color: var(--Main-pallet-Grayscale-0, #FFF);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
            letter-spacing: 0.16px;

            img {
              width: 150px;
              height: 150px;
              object-fit: contain;
            }


            span {
              background-color: unset !important;
            }

            li {
              list-style-position: inside;
            }
          }

          .img_wrapper {
            width: 160px;
            height: 160px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              max-height: 100%;
            }
          }
        }

        footer {
          display: flex;
          padding: 10px 10px;
          background-color: #999999;
          margin-top: 20px;
        }
      }

      .images_content {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .header {
          display: flex;
          gap: 3px;
          justify-content: center;

          .language {
            text-transform: uppercase;
          }
        }

        .color{
          color: rgb(151, 255, 151);
        }

        .image_item_wrapper{
          display: flex;
          flex-direction: column;

          .image_wrapper {
            width: 160px;
            height: 160px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            box-shadow: 1px 1px 13px -7px #000;
          }
        }
      }
    }
  }
  .right {
    // display: flex;
    display: none;
    flex-direction: column;
    gap: 20px;
    width: 20%;
    h3{
      text-align: center;
    }
    .settings_block{
      display: flex;
      align-items: flex-end;
      gap: 15px;
      border-bottom: 1px solid rgb(167, 167, 167);
      padding: 15px 0;
      .input_wrapper{
        flex: 1 0 auto;
        .title{
          font-size: 14px;
        }
        input{
          height: 30px;
          width: 100%;
        }
      }
    }
  }
}

.tools {
  position: relative;
}

.add_buttons {
  display: flex;
  gap: 20px;
}

