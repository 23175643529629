html {
  &::-webkit-scrollbar {
     display: none;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #000;
}

.html_content p,
.html_content h1,
.html_content h2,
.html_content h3
.html_content h4
.html_content h5 {
  all: revert;
}

body {
  font-size: 12px;
  font-weight: 400;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  color: #FFF;
  background-color: #14161D;
  height: 100%;
  min-height: 100vh;
}

input:not([type='radio']):not([type='checkbox']), textarea, select {
  width: 100%;
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 12px;
  border: 2px solid var(--Main-pallet-Grayscale-40, #A9A9A9);

  color: var(--Main-pallet-Grayscale-20, #E8E9E9);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
  background: unset;

  &:focus {
    border: 2px solid var(--Main-pallet-Accent-100, #EC7C40);
    outline: unset;
  }

  &.error {
    border: 2px solid var(--Main-pallet-Additional-Error, #FF0004);
    outline: unset;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  box-shadow: 0 0 0 30px #1E1E21 inset;
  -webkit-text-fill-color: var(--Main-pallet-Grayscale-20, #E8E9E9) !important;
}

input[type="radio"]:focus {
  outline: none;
}

input[type="radio"] {
  display: grid;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1.6px solid #9DA6AA;
  appearance: none;
  background-color: transparent;
  place-content: center;
  cursor: pointer;
}

input[type="radio"]::before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 100%;
  box-shadow: inset 1em 1em 100px #EC7C40;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:checked {
  border-color: #EEEFF1;
}

input[type="checkbox"]:focus {
  outline: none;
}

input[type="checkbox"] {
  display: grid;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1.6px solid #9DA6AA;
  appearance: none;
  background-color: transparent;
  place-content: center;
  cursor: pointer;
  position: relative;
}

input[type="checkbox"]::before {
  content: "";
  width: 18px;
  height: 18px;
  background-color: transparent;
  box-shadow: inset 1em 1em 100px #EC7C40;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  border-radius: 4px;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:checked {
  border-color: #EEEFF1;
}

input[type="checkbox"]:checked::after {
  content: "✔";
  color: white;
  font-size: 12px;
  position: absolute;
  top: -1px;
  left: 3px;
  z-index: 5;
}

input[type='file']{
  border: none !important;
}

@mixin lang_block {
  display: flex;
  gap: 5px;
  user-select: none;

  .lang_item {
    display: flex;
    padding: 5px 10px;
    align-items: center;
    gap: 4px;
    border-radius: 20px;
    border: 1px solid var(--Main-pallet-Accent-90, #EC722E);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15) inset;
    min-width: 48px;

    &:hover {
      cursor: pointer;
    }

    &_active {
      border: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
      background: var(--Main-pallet-Accent-90, #EC722E);

      .lang {
        color: var(--Main-pallet-Grayscale-70, #2D2D2D);
      }
    }
  }

  .lang {
    color: var(--Main-pallet-Accent-90, #EC722E);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    margin: 0 auto;

  }
}

@mixin form {
  width: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  border-radius: 15px;
  background: var(--Main-pallet-Grayscale-90, #1E1E21);

  h2 {
    color: var(--Main-pallet-Accent-100, #EC7C40);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 41.6px */
    letter-spacing: 0.32px;
  }

}

.storage {
  display: flex;
  padding: 5px 10px;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  border: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
  background: var(--Main-pallet-Accent-90, #EC722E);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15) inset;
  width: fit-content;
  margin-bottom: 10px;
  cursor: pointer;
}

.tox{
  width: 100% !important;
  //height: 150px !important;
}

.tox-tinymce {
  border: 1px solid #ccc !important;
}

.tox .tox-statusbar {
  background-color: #232428 !important;
  border-top: 1px solid #ccc !important;
}

.tox .tox-toolbar-overlord>.tox-toolbar, .tox .tox-toolbar-overlord>.tox-toolbar__overflow, .tox .tox-toolbar-overlord>.tox-toolbar__primary {
  background-color: #232428 !important;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  background-color: #232428 !important;
  border-bottom: 1px solid #ccc !important;
}

.image_left_float {
  float: left !important;
  margin: 15px 15px 15px 0px !important;
}

.image_right_float{
  float: right !important;
  margin: 15px 0px 15px 15px !important;
}

.image_left {
  display: block;
  margin-right: auto !important;
  margin-left: 0 !important;
}

.image_right {
  display: block;
  margin-left: auto !important;
  margin-right: 0 !important;
}

.image_center {
  display: block;
  margin: 0 auto;
}

.swiper-button-disabled{
  opacity: 0 !important;
  visibility: hidden !important;
}

@media(max-width: 550px){
  .image_left_float{
    float: none;
    margin: 0;
    width: 100%;
  }

  .image_right_float{
    float: none;
    margin: 0;
    width: 100%;
  }
}
