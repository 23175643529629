.main {
  width: 100%;
  height: 100%;
  display: flex;
  background: var(--Main-pallet-Grayscale-100, #161618);
  min-height: 100vh;
  overflow-x: hidden;

  &_content {
    width: 100%;
    height: 100%;
    margin-left: 280px;
    position: relative;
    padding: 24px 60px 24px 0;
  }
}
