.left_menu {
  background: var(--Main-pallet-Grayscale-90, #1E1E21);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  width: 260px;
  z-index: 5;
  padding: 24px;

  .overflow {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    gap: 24px;

    &::-webkit-scrollbar {
      display: none;
    };
  }


  h2 {
    color: var(--Main-pallet-Grayscale-50, #767676);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */

    display: flex;
    justify-content: space-between;
    align-items: center;

    .arrow {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .menu {
    display: flex;
    padding: 48px 0 0;
    flex-direction: column;
    align-items: center;

    &_item {
      padding: 8px;
      display: flex;
      align-items: center;
      gap: 8px;
      transition: all 0.3s ease;
      border-radius: 12px;
      width: 100%;

      color: var(--Main-pallet-Grayscale-20, #E8E9E9);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      letter-spacing: 0.16px;
      text-decoration: none;

      .title_bar {
        text-align: start;
        line-height: 130%;
      }

      .iconItem {
        width: 24px;
        height: 24px;
        transition: all 0.3s ease;
      }

      &:hover, &.active {
        cursor: pointer;
        background: var(--main-pallet-accent-70-opacity-10, rgba(182, 78, 23, 0.10));
        color: var(--Main-pallet-Accent-100, #EC7C40);

        .iconItem path {
          stroke: #EC7C40;
        }
      }
    }

    &_main {
      margin: 12px 0 16px;
      width: 100%;
      height: 100%;
      max-height: fit-content;
      transition: all 0.7s ease-in-out;
      overflow: hidden;

      .arrow {
        transform: rotate(0);
        transition: all 0.5s ease-in-out;
      }

      &.hide {
        height: 21px;

        .arrow {
          transform: rotate(180deg);
        }
      }
    }

    &_general {
      width: 100%;
    }
  }

  .bottom_menu {
    padding-top: 5px;
  }
}

.delete_icon {
  margin-left: auto;
  width: 18px;
  height: 18px;
}

.plus {
  margin-left: auto;
  margin-right: 5px;

  &:hover {
    cursor: pointer;
  }
}

.deletedElement {
  span {
    color: var(--Main-pallet-Accent-100, #EC7C40);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.16px;
  }
}