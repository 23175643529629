.filter_buttons {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  .button {
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    border: 1px solid var(--Main-pallet-Accent-90, #EC722E);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15) inset;
    transition: .3s;

    &_active {
      background: var(--Main-pallet-Accent-90, #EC722E);
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

}

.card {
  width: 200px;
  height: 230px;
  border: 1px solid #1E1E21;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  &_top {
    background-color: #1E1E21;
    height: 30px;
    border-radius: 10px 10px 0 0;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 5px;
    cursor: pointer;

    .check {
      width: 20px;
      height: 20px;

      path {
        stroke: #767676;
      }
    }

    p {
      color: #EC722E;
    }

    span {
      color: #767676;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px; /* 200% */
    }

    .delete {
      position: absolute;
      right: 5px;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }

    .copy {
      width: 15px;
      height: 15px;
      cursor: pointer;

    }
  }

  &_image {
    margin: auto;
    width: 100%;
    height: 180px;
    text-align: center;

    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;

  &_icon {
    width: 50px;
    height: 50px;
  }

  p {
    text-align: center;
    word-break: break-word;
    width: 80%;
    margin: 0 auto;
    color: #CCCCCC;
  }
}