.header_section {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.wrapper_lang {
  position: relative;
  margin-left: auto;
  margin-right: 10px;
  border-radius: 16px 16px 0 0;

  &_open {
    background: var(--Main-pallet-Grayscale-90, #1E1E21);
  }

  .dropdownMenu {
    position: absolute;
    top: 30px;
    left: 0;
    border-radius: 0 0 16px 16px;
    padding: 10px 0;
    width: 100%;
    z-index: 1000;
    background: var(--Main-pallet-Grayscale-90, #1E1E21);

    .menu_item {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      letter-spacing: 0.16px;
      padding: 7px 16px;
      display: flex;
      gap: 20px;
      text-transform: uppercase;
      align-items: center;
      transition: .3s;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .menu_item:hover {
      cursor: pointer;
      background: #2A2A2D;
    }
  }

  .lang {
    display: flex;
    padding: 7px 16px;
    align-items: center;
    border-radius: 16px 0;
    color: var(--Main-pallet-Grayscale-0, #FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.16px;

    &:hover {
      cursor: pointer;
    }
  }
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .button {
    width: max-content;
  }
}

.description {
  color: var(--Main-pallet-Grayscale-30, #CCC);
  width: 791px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
}


.title {
  color: var(--Main-pallet-Accent-100, #EC7C40);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 41.6px */
  letter-spacing: 0.32px;
}

.wrap_right {
  .button {
    width: max-content;
  }
}