.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(133, 133, 133, 0.20);
  backdrop-filter: blur(3.5px);
  padding-left: 240px;
  z-index: 20;
}

.modal {
  background: #161618;
  margin-bottom: 20px;
  border-radius: 20px;
  animation: fadeIn 0.5s ease-in-out;
  display: flex;
  width: 900px;
  height: 700px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  flex-shrink: 0;
  box-sizing: border-box;
  position: relative;

  &_top {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;

    h1 {
      color: var(--Main-pallet-Accent-100, #EC7C40);
      font-family: Rubik;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 41.6px */
      letter-spacing: 0.32px;
    }

    .close {
      position: absolute;
      right: 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;

      path {
        stroke: crimson;
      }
    }
  }

  &_bottom {
    align-self: flex-end;
    cursor: pointer;
    margin-top: auto;
    width: fit-content;
  }
}